/* tslint:disable: no-any */
/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import log from 'loglevel';

import traverson from 'traverson';

import JsonHalAdapter from 'traverson-hal';

traverson.registerMediaType(JsonHalAdapter.mediaType, JsonHalAdapter);

type Builder = ReturnType<typeof traverson.from>;

interface Traversal {
  continue(): Builder;
}

interface ErrorResponse {
  error: string;
  message: string;
  code?: string;
}

const formatErrorMessage = ({error, message, code}: ErrorResponse) =>
  `${error}: ${message}${code ? ' #' + code : ''}`;

const resolveWithTraversal = <T>(resolve: (result: {resource: T; traversal?: Api}) => void) => (
  resource: T,
  traversal?: Traversal
) => resolve({resource, traversal: traversal && new Api(traversal.continue())});

const checkResponse = <T>(
  resolve: (result: T, traversal?: Traversal) => void,
  reject: (e: Error) => void
) => (e: any, r: any, t?: Traversal) => {
  log.debug('traverson result', e, r, t);

  if (e) {
    log.debug('got an error from traverson, rejecting promise');
    reject(new Error(e));
    return;
  }

  if (!r.statusCode && !r.body) {
    resolve(r as T, t);
    return;
  }

  const body = JSON.parse(r.body);

  if (r.statusCode >= 400) {
    log.debug(`return code is ${r.statusCode}, rejecting promise`);
    reject(new Error(formatErrorMessage(body)));
  } else {
    resolve(body as T, t);
  }
};

export class Api {
  static from(url: string) {
    return new Api(
      traverson
        .from(url)
        // @ts-ignore
        .preferEmbeddedResources(true)
    );
  }

  builder: Builder;

  constructor(builder: Builder) {
    this.builder = builder;
  }

  from(url: string) {
    return new Api(
      this.builder
        // @ts-ignore
        .from(url)
    );
  }

  withRequestOptions(options: any) {
    this.builder = this.builder.withRequestOptions(options);
    return this;
  }

  post(body: any) {
    return new Promise<any>((resolve, reject) => {
      this.builder
        // @ts-ignore
        .addRequestOptions({
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .post(body, checkResponse<any>(resolve, reject));
    });
  }

  jsonHal() {
    return new Api(this.builder.jsonHal());
  }

  getResource<T>() {
    return new Promise<T>((resolve, reject) => {
      this.builder.getResource(checkResponse<T>(resolve, reject));
    });
  }

  getResourceWithTraversal<T>() {
    return new Promise<{resource: T; traversal?: Api}>((resolve, reject) => {
      this.builder.getResource(checkResponse<T>(resolveWithTraversal(resolve), reject));
    });
  }

  newRequest() {
    return new Api(this.builder.newRequest());
  }

  follow(link: string, ...rest: string[]) {
    this.builder = this.builder.follow(link, ...rest);
    return this;
  }
}
