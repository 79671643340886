/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
export const CLASS_SCOPE_PREFIX = 'plugin-roster__';

export const MOBILE_WIDTH_BREAKPOINT = 768;

export const TOP_BAR_HEIGHT_PX = '60px';

export const DEFAULT_PAGE_PADDING = 20;

export const BRANCH_COLOR = '#00A4FD';
export const BRANCH_COLOR_DARK = '#00A4FD';
export const BRANCH_COLOR_CONTRAST = '#008CD8';
export const BRANCH_COLOR_OPAQUE = 'rgba(0, 164, 253, 0.8)';
export const TEXT_COLOR = '#FFFFFF';
export const TEXT_COLOR_LIGHT = '#7c7c7c';
export const TEXT_COLOR_GRAY = '#818181';
export const PRIMARY_TEXT_COLOR = '#1a1a1a';
export const SECONDARY_TEXT_COLOR = '#7c7c7c';
export const TERNARY_TEXT_COLOR = '#818181';

export const THEMES = {
  custom: {
    branchColor: BRANCH_COLOR,
    branchColorOpaque: BRANCH_COLOR_OPAQUE,
    branchColorDark: BRANCH_COLOR_DARK,
    branchColorContrast: BRANCH_COLOR_CONTRAST,
    textColor: TEXT_COLOR,
    textColorLight: TEXT_COLOR_LIGHT,
  },
  active: {
    textPrimaryColor: PRIMARY_TEXT_COLOR,
    textSecondaryColor: SECONDARY_TEXT_COLOR,
  },
  inactive: {
    branchColor: TERNARY_TEXT_COLOR,
    textPrimaryColor: TERNARY_TEXT_COLOR,
    textSecondaryColor: TERNARY_TEXT_COLOR,
  },
};
