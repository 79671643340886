/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import styled, {css} from 'styled-components';

import {CLASS_SCOPE_PREFIX} from '../../constants/styling';
import {flexInline, textHighlightColor} from '../commons/styles';

import {Button} from '../button';

const PREFIX = `${CLASS_SCOPE_PREFIX}calendar`;

export const Wrapper = styled.div.attrs({
  className: `${PREFIX}__wrapper`,
})`
  width: 100%;
`;

export const Heading = styled.h1.attrs({
  className: `${PREFIX}__heading`,
})`
  font-size: 30px;
  font-weight: bold;

  ${flexInline}
  justify-content: space-between;
`;

const calendarButton = css`
  flex: none;

  border: 1px solid #e2e2e2;
  background-color: #fcfcfc;

  ${flexInline}
  justify-content: center;

  &:hover {
    ${({theme}) =>
      css`
        border: 1px solid ${theme.branchColor};
      `}
  }
`;

export const NavigationButton = styled(Button)`
  ${calendarButton}

  margin: 0;

  width: 36px;
  height: 36px;
`;

export const TodayButton = styled(Button)`
  ${calendarButton}

  height: 36px;

  padding: 8px 16px;
  margin: 0 0 0 21px;

  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #7c7c7c;
`;

export const Table = styled.table.attrs({
  className: `${PREFIX}__table`,
})`
  margin: 32px 0;

  width: 100%;
  table-layout: fixed;
`;

export const TableWrapper = styled.div.attrs({
  className: `${PREFIX}__table-navigation-wrapper`,
})`
  flex: 1 0px;
`;

export const TableNavigationWrapper = styled.div.attrs({
  className: `${PREFIX}__table-navigation-wrapper`,
})`
  width: 100%;

  ${flexInline}
`;

export const TH = styled.th.attrs({
  className: `${PREFIX}__table-header`,
})`
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: #797979;
`;

export interface DayProps {
  readonly today?: boolean;
  readonly highlighted?: boolean;
}

const dot = css`
  ::after {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;

    background: ${(props) => props.theme.branchColor};
    border-radius: 100%;

    height: 7px;
    width: 7px;

    bottom: 11px;

    content: '';
  }
`;

const circle = css`
  ::before {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;

    border: 2px solid ${(props) => props.theme.branchColor};
    border-radius: 100%;

    height: 42px;
    width: 42px;

    content: '';
  }
`;

export const TD = styled.td<DayProps>`
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;

  ${({today}) =>
    today
      ? css`
          ${textHighlightColor}
          ${circle}
        `
      : 'color: #1d1d1d;'}
  }

  position: relative;
  margin: 21px 10px;

  line-height: 42px;

  padding-top: 7px;
  padding-bottom: 28px;

  ${({highlighted}) =>
    highlighted &&
    css`
      ${dot}
    `}
`;
