/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
// These must be the first two lines in this file.
// Otherwise IE11 support will not work.

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import log from 'loglevel';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {RouterProvider} from 'react-router5';

import App from './App';
import {NoShiftMessage} from './components/no-shift-message';
import {SettingsPage} from './components/settings';
import {ShiftPlan} from './components/shift-plan';
import './index.css';
import {createRouter} from './router';
import * as serviceWorker from './serviceWorker';
import {Shift, ShiftDetails} from './types/shift';

import {loadTranslations} from './i18n';
import {setupLogs} from './log';
import {PersonalDataPlan} from './views/personal-data/plan';
import {PersonalData} from './types/personal_datum';
import {Holiday} from './types/holiday';
import {MonthlyTraining} from './types/training';

// On IE11 and Edge the history API throws an 'unspecified error'
// unless the history state is reset here
window.history.replaceState(null, '', null);

setupLogs();
loadTranslations();

log.debug('starting roster plugin');
log.debug('env', process.env);

const renderShifts = (
  shifts: Shift[],
  selectedShift?: ShiftDetails,
  selectShift?: (shift?: Shift) => void
) => <ShiftPlan shifts={shifts} detail={selectedShift} onSelectShift={selectShift} />;

const renderPersonalData = (
  personalData: PersonalData,
  holidays: Holiday[],
  trainings: MonthlyTraining
) => <PersonalDataPlan personalData={personalData} holidays={holidays} trainings={trainings} />;

const renderSettings = () => <SettingsPage />;

interface ErrorBoundaryProps {
  readonly onError: (e: Error) => void;
}

interface ErrorBoundaryState {
  readonly hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError() {
    return {hasError: true};
  }
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error: Error) {
    this.props.onError(error);
  }

  render() {
    if (this.state.hasError) {
      return <NoShiftMessage />;
    }

    return this.props.children;
  }
}

const router = createRouter();

const renderError = ({message}: Error) => {
  log.error(message);
  router.navigate('empty');
};

router.start(() => {
  ReactDOM.render(
    <RouterProvider router={router}>
      <ErrorBoundary onError={renderError}>
        <App
          renderShifts={renderShifts}
          renderPersonalData={renderPersonalData}
          renderSettings={renderSettings}
          renderError={renderError}
        />
      </ErrorBoundary>
    </RouterProvider>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
