// tslint:disable:no-var-requires
/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
const getColorLum = require('./branchStyles/getColorLum');
const convertColorLum = require('./branchStyles/convertColorLum');
const convertColorOpacity = require('./branchStyles/convertColorOpacity');

export interface CustomTheme {
  branchColor: string;
  branchColorOpaque: string;
  branchColorDark: string;
  branchColorContrast: string;
  textColor: string;
  textColorLight: string;
}
const ADMIN_THEME = {
  theming_bg: '#00a4fd',
  theming_text: '#fff',
};

export const getThemeColors = ({
  theming_bg: bgColor,
  theming_text: textColor,
}: Record<string, string> = ADMIN_THEME): CustomTheme => {
  const bgColorLum = getColorLum(bgColor);
  const bgColorDarkened1 = bgColorLum > 240 ? convertColorLum(bgColor, -0.1) : bgColor;
  const bgColorDarkened2 = bgColorLum > 160 ? convertColorLum(bgColor, -0.5) : bgColor;
  const bgColorDarkened2Opaque = convertColorOpacity(bgColorDarkened1, 0.8);
  const contrastBgColor = convertColorLum(bgColor, -0.145);
  const lightTextColor = convertColorOpacity(textColor, 0.8);

  return {
    branchColor: bgColorDarkened1,
    branchColorOpaque: bgColorDarkened2Opaque,
    branchColorDark: bgColorDarkened2,
    branchColorContrast: contrastBgColor,
    textColor,
    textColorLight: lightTextColor,
  };
};
