/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import log from 'loglevel';
import {useEffect, useState} from 'react';
import {useRoute} from 'react-router5';
import traverson from 'traverson';

import JsonHalAdapter from 'traverson-hal';
import {useLocalStorage} from '.';
import {Api} from '../api';
import {withTrailingSlash} from '../helpers/string-utils';
import {tokenIsExpired} from '../helpers/token-utils';

traverson.registerMediaType(JsonHalAdapter.mediaType, JsonHalAdapter);

const {REACT_APP_API_URL = 'http://localhost:8000'} = window._env_ || process.env;

interface AuthResponse {
  readonly access_token: string;
}

const useToken = (onError: (e: Error) => void) => {
  const [token, setToken] = useState<string | undefined>();
  const [storageToken, setStorageToken] = useLocalStorage('token');
  const {route} = useRoute();

  useEffect(() => {
    if (route && route.params && route.params.jwt) {
      log.debug('fetching token from API');
      Api.from(withTrailingSlash(REACT_APP_API_URL) + 'auth')
        .withRequestOptions({
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${route.params.jwt}`,
          },
        })
        .post({})
        .then((response: AuthResponse) => setToken(response.access_token))
        .catch(onError);
    } else if (storageToken && !tokenIsExpired(storageToken)) {
      // storage token as fallback
      log.debug('using token from local storage: ', storageToken);
      setToken(storageToken);
    }
  }, [route, storageToken, onError]);

  if (token && token !== storageToken) {
    setStorageToken(token);
  }

  return token;
};

export const useApi = (onError: (e: Error) => void) => {
  const token = useToken(onError);
  const [api, setApi] = useState<Api | undefined>();

  useEffect(() => {
    if (token) {
      setApi(
        Api.from(REACT_APP_API_URL).withRequestOptions({
          headers: {Authorization: `Bearer ${token}`},
        })
      );
    }
  }, [token, onError]);

  return api;
};
