/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {css} from 'styled-components';

export const textHighlightColor = css`
  color: ${(props) => props.theme.branchColor};
`;

export const primaryTextColor = css`
  color: ${(props) => props.theme.textPrimaryColor};
`;

export const secondaryTextColor = css`
  color: ${(props) => props.theme.textSecondaryColor};
`;

export const primaryText = css`
  ${primaryTextColor}
  font-size: 16px;
`;

export const secondaryText = css`
  ${secondaryTextColor}
  font-size: 14px;
`;

export const flexCentered = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const flexTop = css`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
`;

export const flexLeft = css`
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const flexColumnLeft = css`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

export const flexInline = css`
  display: flex;
  align-items: center;
`;

export const icon = css`
  line-height: 1;
  margin-right: 8px;
  width: 16px;
  height: 17px;
`;

export const inputs = css`
  margin-top: 10px;
  margin-bottom: 24px;

  width: 100%;
  height: 48px;

  padding: 0 14px;
  border-radius: 3px;
  border: 1px solid #e2e2e2;

  background: #fbfbfb;
  color: #000;
`;

export const card = css`
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  padding: 12px 24px 12px 8px;
`;
