/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from 'react-modal';
import * as Luxon from 'luxon';

import {
  BackIcon,
  CloseButton,
  DateCell,
  Month,
  NoTrainings,
  TrainingTitle,
  VacationWrapper,
} from './training-modal.style';
import {Header} from '../personal-datum-detail/personal-datum-detail.style';
import {MonthlyTraining} from '../../types/training';
import {dateRangeToString} from '../../helpers/date-utils';

Modal.setAppElement(document.getElementById('root')!);

interface TrainingModalProps {
  readonly isOpen: boolean;
  readonly onClose?: () => void;
  readonly trainings: MonthlyTraining;
}

export const TrainingModal: FunctionComponent<TrainingModalProps> = ({
  isOpen,
  onClose,
  trainings,
}) => {
  const {t} = useTranslation();
  const currentYear = new Date().getFullYear();
  const sortedTrainingMonths = Object.keys(trainings.trainings).sort((a, b) => {
    return a < b ? -1 : a > b ? 1 : 0;
  });

  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: 0,
          border: 0,
          borderRadius: 0,
          overflow: 'hidden',
        },
      }}
    >
      <CloseButton onClick={onClose}>
        <BackIcon />
        {t('my_personal_data')}
      </CloseButton>
      <VacationWrapper>
        <Header>
          {t('training_header')} {currentYear}
        </Header>
        {sortedTrainingMonths.length === 0 && <NoTrainings>{t('no_trainings')}</NoTrainings>}
        <table>
          <tbody>
            {sortedTrainingMonths.map((month) => {
              const monthString = Luxon.DateTime.fromISO(month).toLocaleString({
                month: 'long',
                locale: 'de',
              });
              return [
                <tr key={monthString}>
                  <Month colSpan={2}>{monthString}</Month>
                </tr>,
                ...trainings.trainings[month]
                  .sort((a, b) =>
                    a.start_date < b.start_date ? -1 : a.start_date > b.start_date ? 1 : 0
                  )
                  .map((item, index) => (
                    <tr key={item.name}>
                      <DateCell>{dateRangeToString(item.start_date, item.end_date)}</DateCell>
                      <TrainingTitle>{item.name}</TrainingTitle>
                    </tr>
                  )),
              ];
            })}
          </tbody>
        </table>
      </VacationWrapper>
    </Modal>
  );
};
