/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import styled, {css} from 'styled-components';

import {CLASS_SCOPE_PREFIX} from '../../constants/styling';
import {
  card,
  flexCentered,
  flexColumnLeft,
  flexInline,
  icon,
  primaryText,
  secondaryText,
  textHighlightColor,
} from '../commons/styles';

const PREFIX = `${CLASS_SCOPE_PREFIX}shift-card__`;

interface ShiftCardContainerProps {
  readonly selected?: boolean;
}

const arrow = css`
  position: relative;

  ::before,
  ::after {
    content: '';
    position: absolute;

    top: 50%;
    right: -30px;

    width: 0px;
    height: 0px;

    box-sizing: border-box;
    background: transparent;
    transform-origin: 0px 0px;
    transform: translateY(-16px) rotate(45deg) skew(20deg, 20deg);

    box-shadow: rgb(235, 235, 235) -1px 1px 0px 0px;
    border-color: transparent transparent white white;

    border-width: 8px;
    border-style: solid;
    border-image: initial;
  }

  ::before {
    box-shadow: rgba(0, 0, 0, 0.03) -3px 3px 2px;
  }

  ::after {
    box-shadow: rgba(0, 0, 0, 0.03) -1.2px 1.2px 0px 0px;
  }
`;

export const ShiftCardContainer = styled.div.attrs({
  className: `${PREFIX}wrapper`,
})<ShiftCardContainerProps>`
  ${flexColumnLeft}
  ${card}

  ${({theme, selected}) => selected && `border: 1px solid ${theme.branchColor};`};

  ${({selected}) => selected && arrow};

  position: relative;

  margin: 6px 0;
  cursor: pointer;

  height: 82px;
`;

export const ShiftCardListContainer = styled.div.attrs({
  className: `${PREFIX}list-wrapper`,
})`
  ${flexCentered}
  justify-content: flex-start;
  align-items: stretch;
`;

export const SameDayIndicator = styled.div`
  display: block;
  width: 3px;
  height: 100%;
  border-radius: 4px;
  background: ${(props) => props.theme.branchColor};
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
`;

export const Content = styled.div.attrs({
  className: `${PREFIX}content`,
})`
  width: 100%;
  margin-bottom: 20px;
`;

export const DateRow = styled.section`
  ${flexInline}
  padding-bottom: 12px;
`;

export const MetaRow = styled.section`
  ${flexInline}
`;

export const Icon = styled.div`
  ${flexInline}
  ${textHighlightColor}
  ${icon}
`;

export const SecondaryIcon = styled.div`
  ${textHighlightColor}
  ${icon}
  width: 12px;
`;

export const StoreName = styled.div`
  ${secondaryText}
  letter-spacing: 0.3px;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CurrentDay = styled.div`
  ${textHighlightColor}
  font-weight: bold;
  margin-right: 8px;
  letter-spacing: 0.36px;
`;

export const ShiftDate = styled.div`
  ${primaryText}
  font-weight: bold;
  letter-spacing: 0.36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ShiftDateSpan = styled.div`
  ${secondaryText}
  margin-right: 12px;
  letter-spacing: 0.3px;
  white-space: nowrap;
`;
