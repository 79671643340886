/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';

import {Calendar} from '../calendar';

import {dateToISOWeek} from '../../helpers/date-utils';
import {useToday} from '../../hooks/calendar';
import {Shift, ShiftDetails, WeeklyShiftsList} from '../../types/shift';
import {NoShiftMessage} from '../no-shift-message';
import {ShiftCardList} from '../shift-card';
import {ShiftDetail} from '../shift-detail';
import {
  ShiftCardContainer,
  ShiftCardListWrapper,
  ShiftDetailWrapper,
} from './extensive-shift-plan.style';

export interface ExtensiveShiftPlanProps {
  readonly shifts: WeeklyShiftsList;
  readonly detail?: ShiftDetails;
  readonly onSelectShift?: (shift?: Shift) => void;
}

export const ExtensiveShiftPlan: FunctionComponent<ExtensiveShiftPlanProps> = ({
  shifts,
  detail,
  onSelectShift,
}) => {
  const today = useToday();
  const thisWeek = useMemo(() => dateToISOWeek(today), [today]);
  const [selectedWeek, selectWeek] = useState(thisWeek);

  useEffect(() => {
    if (!onSelectShift) {
      return;
    }

    if (!detail && shifts[selectedWeek] && shifts[selectedWeek].length > 0) {
      onSelectShift(shifts[selectedWeek][0]);
    } else if (!shifts[selectedWeek]) {
      onSelectShift(undefined);
    }
  }, [detail, selectedWeek, shifts, onSelectShift]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Calendar shifts={shifts} onSelectShift={onSelectShift} onSelectWeek={selectWeek} />
      {shifts[selectedWeek] ? (
        <ShiftCardContainer>
          <ShiftCardListWrapper>
            <ShiftCardList
              shifts={shifts[selectedWeek] || []}
              selectedShift={detail}
              onShiftClick={onSelectShift}
            />
          </ShiftCardListWrapper>
          {detail && (
            <ShiftDetailWrapper>
              <ShiftDetail shiftDetails={detail} />
            </ShiftDetailWrapper>
          )}
        </ShiftCardContainer>
      ) : (
        <NoShiftMessage />
      )}
    </>
  );
};
