/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {isEqual} from 'lodash';
import log from 'loglevel';
import {useEffect, useState} from 'react';
import {useRoute} from 'react-router5';

import {Api} from '../api';

import {Shift, ShiftDetails, ShiftDetailsMachine, ShiftDetailsPersonnel} from '../types/shift';

const fetchShiftDetails = (api: Api, shift: Shift) =>
  api.newRequest().follow(`sh:shift[name:${shift.id}]`).getResource<ShiftDetails>();

const fetchShiftMachines = (api: Api, shift: Shift) =>
  api
    .newRequest()
    .follow(`sh:shift[name:${shift.id}]`)
    .follow('machines')
    .getResource<ShiftDetailsMachine[]>();

const fetchShiftPersonnel = (api: Api, shift: Shift) =>
  api
    .newRequest()
    .follow(`sh:shift[name:${shift.id}]`)
    .follow('personnel')
    .getResource<ShiftDetailsPersonnel[]>();

const fetchShifts = (api: Api) => api.newRequest().follow('sh:shift[$all]').getResource<Shift[]>();

const useShiftApi = (api: Api | undefined, onError: (e: Error) => void) => {
  const {route} = useRoute();
  const [shiftApi, setShiftApi] = useState<Api | undefined>();

  useEffect(() => {
    if (api && route.name === 'shifts') {
      api
        .newRequest()
        .jsonHal()
        .follow('sh:shifts')
        .getResourceWithTraversal()
        .then(({traversal}) => {
          setShiftApi(traversal);
        })
        .catch(onError);
    }
  }, [api, route, onError, setShiftApi]);

  return shiftApi;
};

const useCachedShiftDetails = (
  selectedShift: Shift | undefined,
  shiftDetails: ShiftDetails | undefined
) => {
  const [cache, setCache] = useState<{[id: string]: ShiftDetails}>({});

  useEffect(() => {
    if (!shiftDetails) {
      return;
    }

    const cachedShiftDetails = cache[shiftDetails.id];
    if (!isEqual(shiftDetails, cachedShiftDetails)) {
      setCache({...cache, [shiftDetails.id]: shiftDetails});
    }
  }, [shiftDetails, cache, setCache]);

  return selectedShift && cache[selectedShift.id];
};

const useShiftDetails = (shiftApi: Api | undefined, onError: (e: Error) => void) => {
  const [shiftDetails, setShiftDetails] = useState<ShiftDetails | undefined>();
  const [selectedShift, selectShift] = useState<Shift | undefined>();
  const cachedShiftDetails = useCachedShiftDetails(selectedShift, shiftDetails);

  useEffect(() => {
    if (!selectedShift) {
      log.debug('unset detail');
      setShiftDetails(undefined);
      return;
    }

    if (!shiftApi) {
      return;
    }

    log.debug('fetching details for ', selectedShift);
    fetchShiftDetails(shiftApi, selectedShift)
      .then((shiftDetailsFromApi) => {
        fetchShiftMachines(shiftApi, selectedShift)
          .then((shiftMachines) => {
            fetchShiftPersonnel(shiftApi, selectedShift).then((shiftPersonnel) => {
              shiftMachines.sort((a, b) => (a.start < b.start ? -1 : a.start > b.start ? 1 : 0));
              shiftPersonnel.sort((a, b) => (a.start < b.start ? -1 : a.start > b.start ? 1 : 0));
              setShiftDetails({
                ...shiftDetailsFromApi,
                machines: shiftMachines,
                personnel: shiftPersonnel,
              });
            });
          })
          .catch(onError);
      })
      .catch(onError);
  }, [shiftApi, selectedShift, setShiftDetails, onError]);

  useEffect(() => {
    if (!selectedShift) {
      return;
    }

    if (cachedShiftDetails) {
      log.debug('using cached shift for', selectedShift);
      setShiftDetails(cachedShiftDetails);
    }
  }, [selectedShift, cachedShiftDetails, setShiftDetails]);

  return {
    shiftDetails,
    selectShift,
  };
};
const useShiftList = (shiftApi: Api | undefined, onError: (e: Error) => void) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [shifts, setShifts] = useState<Shift[] | undefined>();

  useEffect(() => {
    if (!shiftApi) {
      return;
    }

    log.debug('fetching shifts');
    fetchShifts(shiftApi)
      .then(setShifts)
      .catch(onError)
      .then(() => setLoading(false));
  }, [shiftApi, onError]);

  return {
    shifts,
    loading,
  };
};

export const useShifts = (api: Api | undefined, onError: (e: Error) => void) => {
  const shiftApi = useShiftApi(api, onError);
  const shiftList = useShiftList(shiftApi, onError);
  const shiftDetails = useShiftDetails(shiftApi, onError);

  return {
    ...shiftList,
    ...shiftDetails,
  };
};
