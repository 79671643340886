/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import * as Luxon from 'luxon';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ThemeProvider} from 'styled-components';

import {
  CurrentDay,
  DateRow,
  Icon,
  MetaRow,
  SameDayIndicator,
  ShiftCardContainer,
  ShiftCardListContainer,
  ShiftDate,
  ShiftDateSpan,
  StoreName,
} from './shift-card.style';

import {isLastWeekOrEarlier, isToday} from '../../helpers/date-utils';
import {getTheme} from '../../helpers/theme-utils';
import {Shift, ShiftDetails} from '../../types/shift';
import {StoreIcon, TimeIcon} from '../commons/icons';
import {Small as OnSmallScreen} from '../screen-size';

interface ListProps {
  readonly shifts: ReadonlyArray<Shift>;
  readonly selectedShift?: Shift | ShiftDetails;
  readonly onShiftClick?: (shift: Shift) => void;
}

interface CardProps {
  readonly shift: Shift;
  readonly selected?: boolean;
  readonly onClick?: () => void;
}

/**
 * Component to render the shift card with the minimal set of informations
 *
 * @param CardProps
 */
export const ShiftCard: React.FC<CardProps> = ({
  shift: {workplace, start, end},
  selected,
  onClick,
}) => {
  const startDate = Luxon.DateTime.fromISO(start);
  const endDate = Luxon.DateTime.fromISO(end);
  const shiftInterval = Luxon.Interval.fromDateTimes(startDate, endDate);
  const shiftIsToday = isToday(startDate);
  const {t} = useTranslation();

  return (
    <ThemeProvider theme={getTheme(isLastWeekOrEarlier(startDate))}>
      <ShiftCardContainer onClick={onClick} selected={selected}>
        <DateRow>
          {shiftIsToday && <CurrentDay>{t('today')}</CurrentDay>}
          <ShiftDate>
            {startDate.toLocaleString({weekday: 'long', month: 'long', day: '2-digit'})}
            {startDate.startOf('day') < endDate.startOf('day') &&
              ' - ' + endDate.toLocaleString({weekday: 'long', month: 'long', day: '2-digit'})}
          </ShiftDate>
        </DateRow>
        <MetaRow>
          <Icon>
            <TimeIcon />
          </Icon>
          <ShiftDateSpan>{shiftInterval.toFormat('t')}</ShiftDateSpan>
          <Icon>
            <StoreIcon />
          </Icon>
          <StoreName>{workplace}</StoreName>
        </MetaRow>
        <OnSmallScreen>{shiftIsToday && <SameDayIndicator />}</OnSmallScreen>
      </ShiftCardContainer>
    </ThemeProvider>
  );
};

export const ShiftCardList: React.FC<ListProps> = ({shifts, selectedShift, onShiftClick}) => {
  return (
    <ShiftCardListContainer>
      {[...shifts]
        .sort((a: Shift, b: Shift) => (a.start > b.start ? 1 : -1))
        .map((shift: Shift) => (
          <ShiftCard
            shift={shift}
            key={`${shift.start}-${shift.id}`}
            selected={selectedShift && shift.id === selectedShift.id}
            onClick={onShiftClick && (() => onShiftClick(shift))}
          />
        ))}
    </ShiftCardListContainer>
  );
};
