/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import styled from 'styled-components';

import {CLASS_SCOPE_PREFIX} from '../../constants/styling';
import {
  flexColumnLeft,
  flexInline,
  primaryText,
  secondaryText,
  textHighlightColor,
} from '../commons/styles';
import {Button} from '../button';

const PREFIX = `${CLASS_SCOPE_PREFIX}shift-detail__`;

export const ShiftDetailsContainer = styled.div.attrs({
  className: `${PREFIX}wrapper`,
})`
  ${flexColumnLeft}

  @media (max-width: 768px) {
    margin-bottom: 75px;
  }
`;

export const Header = styled.h1`
  color: #053cc4;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 28px;
`;

export const InfoSection = styled.section`
  ${flexColumnLeft}
  margin-bottom: 20px;
`;

export const InfoSectionDesktopOnly = styled(InfoSection)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const InfoTable = styled.div`
  ${flexInline}
  align-items: start;
  justify-content: space-between;
  padding: 4px 0;
`;

export const SubHeader = styled.h2`
  font-size: 16px;
  font-weight: semi-bold;
  padding-bottom: 10px;
`;

export const CurrentDay = styled.span`
  ${textHighlightColor}
  font-weight: bold;
  margin-right: 8px;
  letter-spacing: 0.36px;
`;

export const Description = styled.div`
  ${secondaryText}
  padding-right: 20px;
  font-size: 17px;
`;

export const DescriptionSub = styled.div`
  ${secondaryText}
  padding-right: 20px;
  padding-left: 10px;
  font-size: 14px;
`;

export const Content = styled.div`
  ${primaryText}
  text-align: right;
  font-size: 17px;
`;

export const LastUpdateLabel = styled.div`
  ${secondaryText}
  font-size: 14px;
`;

export const CommentBox = styled.div`
  ${secondaryText}
  font-size: 14px;
  padding: 8px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
`;

export const FloatingButtons = styled.div`
  position: fixed;
  bottom: 20px;
  left: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  z-index: 100;

  @media (min-width: 769px) {
    display: none;
  }
`;

export const FloatingButton = styled(Button)`
  position: relative;
  height: 55px;
  padding: 0 10px;
`;

export const FloatingButtonCount = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
  padding: 0 3px 3px 0;
  font-size: 14px;
`;
