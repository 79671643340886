/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, {ReactNode, useEffect} from 'react';
import {useRoute, useRouter} from 'react-router5';
import styled, {ThemeProvider} from 'styled-components';
import log from 'loglevel';

import {flexTop} from './components/commons/styles';
import {NoShiftMessage} from './components/no-shift-message';
import './index.css';
import {Shift, ShiftDetails} from './types/shift';

import {Settings as LuxonSettings} from 'luxon';
import {useApi, useLocalStorage, useShifts} from './hooks';
import {useTheme} from './hooks/theme';
import {decode} from 'jsonwebtoken';
import {usePersonalData} from './hooks/personal_data';
import {useHoliday} from './hooks/holiday';
import {PersonalData} from './types/personal_datum';
import {Holiday} from './types/holiday';
import {MonthlyTraining} from './types/training';
import {useTraining} from './hooks/training';

const {REACT_APP_INSTANCE_ID_SHIFTPLAN, REACT_APP_INSTANCE_ID_PERSONAL_DATA} =
  window._env_ || process.env;

const Wrapper = styled.div`
  ${flexTop}

  align-items: stretch;

  padding: 16px;
  width: 100%;
  height: 100vh;
`;

// set the default locale to English, to make the tests independent from system language
if (process.env.NODE_ENV === 'development') {
  LuxonSettings.defaultLocale = 'en-US';
  LuxonSettings.defaultZoneName = 'utc';
}

export interface AppProps {
  readonly renderShifts: (
    shifts: Shift[],
    selectedShift?: ShiftDetails,
    selectShift?: (shift?: Shift) => void
  ) => ReactNode;
  readonly renderPersonalData: (
    personalData: PersonalData,
    holidays: Holiday[],
    trainings: MonthlyTraining
  ) => ReactNode;
  readonly renderSettings: () => ReactNode;
  readonly renderError: (error: Error) => void;
}

export const App: React.FC<AppProps> = ({
  renderShifts,
  renderPersonalData,
  renderSettings,
  renderError,
}) => {
  const {route} = useRoute();
  const router = useRouter();
  const theme = useTheme();
  const api = useApi(renderError);
  const {shifts, shiftDetails, selectShift} = useShifts(api, renderError);
  const {personalData} = usePersonalData(api, renderError);
  const {holiday} = useHoliday(api, renderError);
  const {training} = useTraining(api, renderError);
  let [storageToken] = useLocalStorage('token');
  if (route.params && route.params.jwt) {
    log.debug('using token from route params');
    storageToken = route.params.jwt;
  }

  const decodedToken = decode(storageToken);
  if (!decodedToken) {
    throw new Error(`couldn't decode token`);
  }
  const {instance_id} = decodedToken as {instance_id: string};
  useEffect(() => {
    if (shifts && shifts.length === 0 && instance_id === REACT_APP_INSTANCE_ID_SHIFTPLAN) {
      router.navigate('empty');
    }
  }, [shifts, router, instance_id]);

  let content: ReactNode = <Wrapper>'Loading...'</Wrapper>;

  if (route?.name === 'shifts' && shifts && personalData && holiday && training) {
    switch (instance_id) {
      case REACT_APP_INSTANCE_ID_SHIFTPLAN:
        // shiftplan
        content = <Wrapper>{renderShifts(shifts, shiftDetails, selectShift)}</Wrapper>;
        break;

      case REACT_APP_INSTANCE_ID_PERSONAL_DATA:
        // personal data
        content = <Wrapper>{renderPersonalData(personalData, holiday, training)}</Wrapper>;
        break;

      default:
        // default
        content = <Wrapper>{renderShifts(shifts, shiftDetails, selectShift)}</Wrapper>;
        break;
    }
  }

  if (route?.name === 'settings') {
    content = renderSettings();
  }

  if (route?.name === 'empty') {
    content = (
      <Wrapper>
        <NoShiftMessage />
      </Wrapper>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">{content}</div>
    </ThemeProvider>
  );
};

export default App;
