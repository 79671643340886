var hexToRGB = require('./hexToRGB');

/**
 * @param  {String} hex
 * @param  {Number} opacity
 * @return {string}
 */
module.exports = function(hex, opacity) {
  var rgb = hexToRGB(hex);

  var r = rgb[0];
  var g = rgb[1];
  var b = rgb[2];

  opacity = typeof opacity === 'number' ? Math.min(Math.max(opacity, 0), 1) : 0.7;

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
};
