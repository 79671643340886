/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import styled from 'styled-components';

import {CLASS_SCOPE_PREFIX} from '../../constants/styling';
import {Button} from '../button';
import {BackIcon as RawBackIcon} from '../commons/icons';
import {flexInline} from '../commons/styles';

const PREFIX = `${CLASS_SCOPE_PREFIX}shift-detail-modal__`;

export const VacationWrapper = styled.div.attrs({
  className: `${PREFIX}detail-wrapper`,
})`
  padding: 16px;
  width: 100%;
  height: calc(100% - 44px);
  overflow-y: scroll;
`;

export const CloseButton = styled(Button).attrs({
  className: `${PREFIX}close-button`,
})`
  margin: 0;
  padding: 0 16px;
  width: 100%;

  background-color: #f6f6f6;
  color: #000;

  ${flexInline}

  font-size: 18px;
  height: 44px;

  border: none;
  border-radius: 0;
`;

export const BackIcon = styled(RawBackIcon).attrs({
  className: `${PREFIX}close-button`,
})`
  margin-right: 16px;
`;
