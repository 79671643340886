/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import * as Luxon from 'luxon';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import Modal from 'react-modal';
import {
  Content,
  Description,
  Header,
  InfoSection,
  InfoTable,
  PersonalDatumDetailsContainer,
  SmallText,
} from './personal-datum-detail.style';

import {ThemeProvider} from 'styled-components';
import {getTheme} from '../../helpers/theme-utils';
import {PersonalData} from '../../types/personal_datum';
import {Button} from '../button';
import {VacationModal} from '../vacation-modal';
import {Holiday} from '../../types/holiday';
import {MonthlyTraining} from '../../types/training';
import {TrainingModal} from '../training-modal';

Modal.setAppElement(document.getElementById('root')!);

interface DetailProps {
  readonly personalDatumDetails: PersonalData;
  readonly holidays: Holiday[];
  readonly trainings: MonthlyTraining;
  readonly showDate?: boolean;
}

/**
 * Title and content are shown in a table styled way
 *
 * @param props
 */
export const InfoTableComponent: React.FC<{tag?: string; description: string; content: string}> = ({
  tag,
  description,
  content,
}) => (
  <InfoTable>
    <Description>{description}</Description>
    <Content data-tag={tag}>{content}</Content>
  </InfoTable>
);

/**
 * Component to show all informations of a shift
 *
 * @param CardProps
 */
export const PersonalDatumDetail: React.FC<DetailProps> = ({
  personalDatumDetails,
  showDate = false,
  holidays,
  trainings,
}) => {
  const {date, AnzahlKrank, IstZeit, SollZeit, PERSONAL_NUMMER} = personalDatumDetails;
  const {t} = useTranslation();
  const [showVacationModal, setShowVacationModal] = React.useState(false);
  const [showTrainingModal, setShowTrainingModal] = React.useState(false);

  return (
    <ThemeProvider theme={getTheme(false)}>
      <PersonalDatumDetailsContainer>
        <VacationModal
          isOpen={showVacationModal}
          onClose={() => setShowVacationModal(false)}
          holidays={holidays}
          personalDatumDetails={personalDatumDetails}
        />
        <TrainingModal
          isOpen={showTrainingModal}
          onClose={() => setShowTrainingModal(false)}
          trainings={trainings}
        />
        <SmallText>
          {t('personalnummer')}: {PERSONAL_NUMMER}
        </SmallText>
        {showDate && (
          <Header>
            {Luxon.DateTime.fromISO(date).toLocaleString({
              month: 'long',
              year: 'numeric',
              locale: 'de',
            })}
          </Header>
        )}
        <InfoSection>
          <InfoTableComponent tag="soll_zeit" description={t('soll_zeit')} content={SollZeit} />
          <InfoTableComponent tag="ist_zeit" description={t('ist_zeit')} content={IstZeit} />
          <InfoTableComponent tag="krank" description={t('anzahl_krank')} content={AnzahlKrank} />
        </InfoSection>
        <Button
          onClick={() => {
            setShowVacationModal(true);
          }}
        >
          {t('vacation_header')}
        </Button>
        <Button
          onClick={() => {
            setShowTrainingModal(true);
          }}
        >
          {t('training_header')}
        </Button>
      </PersonalDatumDetailsContainer>
    </ThemeProvider>
  );
};
