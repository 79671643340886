/**
 * Converts the give rgb values to a rrggbb style
 *
 * @param {string} m match
 * @param {string} r red
 * @param {string} g green
 * @param {string} b blue
 * @return {string} full color string
 */
function RGBToRRGGBB(m, r, g, b) {
  return r + r + g + g + b + b;
}

/**
 * Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
 * @param {string} hexColor color in hex format
 * @return {string} the expanded color
 */
module.exports = function(hexColor) {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  return hexColor.replace(shorthandRegex, RGBToRRGGBB);
};
