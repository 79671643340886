/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import i18next from 'i18next';
import {max, min} from 'lodash';
import {DateTime} from 'luxon';
import React, {useEffect, useRef} from 'react';

import {dateToISOWeek, getRelativeWeek} from '../../helpers/date-utils';
import {Shift, WeeklyShiftsList} from '../../types/shift';
import {EmptyWeekCard} from '../empty-week-card';
import {ShiftCardList} from '../shift-card/shift-card';
import {WeekHeader, WeekListContainer, WeekListsContainer} from './week-list.style';

interface WeekListsProps {
  readonly shifts: WeeklyShiftsList;
  readonly onShiftClick?: (shift: Shift) => void;
}

interface WeekListProps {
  readonly highlighted?: boolean;
  readonly title: string;
  readonly shifts: Shift[];
  readonly onShiftClick?: (shift: Shift) => void;
}

/**
 * Create a title for the given week number and difference
 * @param weekNumber
 * @param difference
 */
const getWeekTitle = (weekNumber: string, difference: number) => {
  switch (difference) {
    case -1:
      return i18next.t('week.last');
    case 0:
      return i18next.t('week.this');
    case 1:
      return i18next.t('week.next');
    default:
      return `${i18next.t('week.other')} ${getWeekNumber(weekNumber)}`;
  }
};

/**
 * Extracts the weeknumber from the ISO string
 * @param weekNumber
 */
const getWeekNumber = (weekNumber: string) => {
  return parseInt(weekNumber.split('W')[1], 10);
};

/**
 * This component returns the elements for one week
 * @param props
 */
export const WeekList: React.FC<WeekListProps> = ({title, shifts, highlighted, onShiftClick}) => {
  const headingRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (highlighted && headingRef && headingRef.current) {
      window.scrollTo(0, headingRef.current.offsetTop);
    }
  }, [highlighted, headingRef]);

  return (
    <WeekListContainer>
      <WeekHeader ref={headingRef} highlighted={highlighted}>
        {title}
      </WeekHeader>
      {shifts && shifts.length ? (
        <ShiftCardList shifts={shifts} onShiftClick={onShiftClick} />
      ) : (
        <EmptyWeekCard />
      )}
    </WeekListContainer>
  );
};

const computeWeekData = (shifts: WeeklyShiftsList) => {
  const weekNumbers = Object.keys(shifts).map(getRelativeWeek());

  const currentWeek = DateTime.local().startOf('week');
  const firstWeek: number = min(weekNumbers)!;
  const lastWeek: number = max(weekNumbers)!;
  const r = [];

  for (let relativeWeek = firstWeek; relativeWeek <= lastWeek; ++relativeWeek) {
    const isoWeek = dateToISOWeek(currentWeek.plus({weeks: relativeWeek}));

    r.push({
      title: getWeekTitle(isoWeek, relativeWeek),
      d: relativeWeek,
      shifts: shifts[isoWeek] || [],
    });
  }

  return r;
};

/**
 * This component takes a grouped list of shifts with the week number as
 * key value. It returns a react component where the weeks have headlines
 * from a schema like in week 35:
 *
 * 2019-W33 -> Week 33
 * 2019-W34 -> Last week
 * 2019-W35 -> This week
 * 2019-W36 -> Next week
 * 2019-W37 -> Week 37
 * 2019-W38 -> Week 38
 *
 * @param Props
 */
export const WeekLists: React.FC<WeekListsProps> = ({shifts, onShiftClick}) => {
  const weekData = computeWeekData(shifts);

  return (
    <WeekListsContainer>
      {weekData.map(({d, title, shifts: weekShifts}) => (
        <WeekList
          key={title}
          highlighted={d === 0}
          title={title}
          shifts={weekShifts}
          onShiftClick={onShiftClick}
        />
      ))}
    </WeekListsContainer>
  );
};
