var hexToRGB = require('./hexToRGB');
var getColorLum = require('./getColorLum');

/**
 * [_HslDarken description]
 * @param  {Array} hsl
 * @param  {Number} percentage
 * @return {String}
 */
function hslDarken(hsl, percentage) {
  var newLightness = hsl[2] - hsl[2] * percentage;
  return hslToRgb(hsl[0], hsl[1], newLightness < 0 ? 0 : newLightness);
}

/**
 * [_HslDarken description]
 * @param  {Array} hsl
 * @param  {Number} percentage
 * @return {String}
 */
function hslLighten(hsl, percentage) {
  // lighten based on luminosity, but if luminosity (near) null: lighten by (double) percentage based on 1
  var modifier = hsl[2] > 0.2 ? hsl[2] * percentage : hsl[2] > 0.08 ? percentage : percentage * 2;
  var newLightness = hsl[2] + modifier;
  return hslToRgb(hsl[0], hsl[1], newLightness > 1 ? 1 : newLightness);
}

/**
 * Converts an RGB color value to HSL.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 * Source: https://gist.github.com/mjackson/5311256 or https://stackoverflow.com/questions/2353211/hsl-to-rgb-color-conversion
 * @param   {number}  r       The red color value
 * @param   {number}  g       The green color value
 * @param   {number}  b       The blue color value
 * @return  {array}           The HSL representation
 */
function rgbToHsl(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var h;
  var s;
  var l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }

    h /= 6;
  }

  return [h, s, l];
}

/**
 * Converts an HUE color value to RGB.
 * @param   {number}  p
 * @param   {number}  q
 * @param   {number}  t
 * @return  {number}
 */
function hue2rgb(p, q, t) {
  if (t < 0) {
    t += 1;
  }
  if (t > 1) {
    t -= 1;
  }
  if (t < 1 / 6) {
    return p + (q - p) * 6 * t;
  }
  if (t < 1 / 2) {
    return q;
  }
  if (t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6;
  }
  return p;
}

/**
 * Converts an HSL color value to RGB.
 * Source: https://gist.github.com/mjackson/5311256 or https://stackoverflow.com/questions/2353211/hsl-to-rgb-color-conversion
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {array}           The RGB representation
 */
function hslToRgb(h, s, l) {
  var r;
  var g;
  var b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r * 255, g * 255, b * 255];
}

/**
 * Convert color to a contrastful value
 * @param  {String} hex
 * @param  {Number} percentage
 * @return {string}
 */
module.exports = function(hex, percentage) {
  percentage = percentage || 0;

  var rgb = hexToRGB(hex);

  // get lumninosity
  var cLum = getColorLum(hex);

  // get rgb min/max
  var min = Math.min(rgb[0], rgb[1], rgb[2]);
  var max = Math.max(rgb[0], rgb[1], rgb[2]);

  // if nearly black/white, fade to grey
  if (max - min < 50 && (cLum > 240 || cLum < 30)) {
    var r = (rgb[0] + rgb[1] + rgb[2]) / 3;
    rgb = [r, r, r];
  }

  // convert RGB to HSL
  var hsl = rgbToHsl(rgb[0], rgb[1], rgb[2]);

  // change luminosity
  if (cLum > 210) {
    rgb = hslDarken(hsl, Math.abs(percentage));
  } else if (cLum < 40 || percentage > 0) {
    rgb = hslLighten(hsl, Math.abs(percentage));
  } else {
    rgb = hslDarken(hsl, Math.abs(percentage));
  }

  // and convert back to RGB
  var rgbhex = '#';
  var c;
  var i;
  for (i = 0; i < 3; i++) {
    c = Math.round(rgb[i]).toString(16);
    rgbhex += ('00' + c).substr(c.length);
  }

  return rgbhex;
};
