var hexToRGB = require('./hexToRGB');

/**
 * @param {String} hex
 *
 * @return {Number} Luminocity value
 */
module.exports = function(hex) {
  // convert to RGB
  var rgb = hexToRGB(hex);
  var r = rgb[0];
  var g = rgb[1];
  var b = rgb[2];

  return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
};
