var expandShortHandColor = require('./expandShortHandColor');

/**
 * converts hex into int
 * @param {string} color color channel two digits
 * @return {number}
 */
function parseHex(color) {
  return parseInt(color, 16);
}

/**
 * Converts a hex string 3 digit or 6 digit into an array with the rgb values
 * @param {string} hexColor
 * @return {number[]}
 */
module.exports = function(hexColor) {
  var shortMatch = /^#?[a-f\d]{3}$/i;
  var rgbMatch = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  var rgbColors = [];

  if (hexColor.match(shortMatch)) {
    hexColor = expandShortHandColor(hexColor);
  }

  if (hexColor.match(rgbMatch)) {
    rgbColors = hexColor.match(rgbMatch).slice(1, 4);
  }

  return rgbColors.map(parseHex);
};
