/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from 'react-modal';
import * as Luxon from 'luxon';

import {BackIcon, CloseButton, VacationWrapper} from './vacation-modal.style';
import {Holiday} from '../../types/holiday';
import {Header, SmallText, SubHeader} from '../personal-datum-detail/personal-datum-detail.style';
import {InfoSection} from '../shift-detail/shift-detail.style';
import {InfoTableComponent} from '../personal-datum-detail/personal-datum-detail';
import {PersonalData} from '../../types/personal_datum';

Modal.setAppElement(document.getElementById('root')!);

interface VacationModalProps {
  readonly isOpen: boolean;
  readonly onClose?: () => void;
  readonly holidays: Holiday[];
  readonly personalDatumDetails: PersonalData;
}

export const VacationModal: FunctionComponent<VacationModalProps> = ({
  isOpen,
  onClose,
  holidays,
  personalDatumDetails,
}) => {
  const {UrlaubAnspruch} = personalDatumDetails;
  const {t} = useTranslation();
  const currentYear = new Date().getFullYear();
  const sortedHolidays = holidays.sort((a, b) => {
    return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
  });

  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: 0,
          border: 0,
          borderRadius: 0,
          overflow: 'hidden',
        },
      }}
    >
      <CloseButton onClick={onClose}>
        <BackIcon />
        {t('my_personal_data')}
      </CloseButton>
      <VacationWrapper>
        <Header>
          {t('vacation_header')} {currentYear}
        </Header>
        <InfoSection>
          <InfoTableComponent tag="urlaub" description={t('urlaub')} content={UrlaubAnspruch} />
          <SmallText>{t('stand_letzte_abrechnung')}</SmallText>
        </InfoSection>
        <InfoSection>
          <SubHeader>{t('geplanter_urlaub')}</SubHeader>
          {Object.entries(sortedHolidays).map(([_, value]) => {
            const month = Luxon.DateTime.fromISO(value.date, {zone: 'utc'}).toLocaleString({
              month: 'long',
              locale: 'de',
            });
            return (
              <InfoTableComponent
                key={month}
                tag={month}
                description={month}
                content={String(value.days)}
              />
            );
          })}
        </InfoSection>
      </VacationWrapper>
    </Modal>
  );
};
