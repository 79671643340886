/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import * as Luxon from 'luxon';

const descriptions = {
  hour: 'duration.hour',
  hours: 'duration.hours',
  minute: 'duration.minute',
  minutes: 'duration.minutes',
};

/**
 * Transform an ISO date to a Luxon Date
 * @param ISODate
 */
export const ISOtoDate = (ISODate: string): Luxon.DateTime => Luxon.DateTime.fromISO(ISODate);

/**
 * Test if the given date is on the same day
 * @param date
 */
export const isToday = (date: Luxon.DateTime): boolean => {
  const actualDate = Luxon.DateTime.local();
  return date.hasSame(actualDate, 'day');
};

/**
 * Test if the given date is in the last week, or earlier
 * @param date
 */
export const isLastWeekOrEarlier = (date: Luxon.DateTime): boolean => {
  const actualDate = Luxon.DateTime.local();
  return date.weekNumber < actualDate.weekNumber;
};

/**
 * Formats the Luxon date to the ISOWeek format `2019-W23`
 * @param date
 */
export const dateToISOWeek = (date: Luxon.DateTime): string => {
  // tslint:disable-next-line:prettier
  const ISOWeekFormat = 'kkkk-\'W\'WW';
  return date.toFormat(ISOWeekFormat);
};

/**
 * Get the start of a week as DateTime
 * @param mod
 */
export const getWeek = (mod: number): Luxon.DateTime =>
  ISOtoDate(dateToISOWeek(Luxon.DateTime.local().plus({week: mod})));

/**
 * Get the start of the actual week as DateTime
 */
export const getActualWeek = (): Luxon.DateTime => getWeek(0);

/**
 * Calculates the week numbers from a given count of days
 * @param days
 */
export const daysToWeek = (days: number): number => days / 7;

/**
 * Calculates the difference between the actualWeek and the otherWeek
 * @param actualWeek
 * @param otherWeek
 */
export const getRelativeWeek = (actualWeek: Luxon.DateTime = getActualWeek()) => (
  otherWeek: string
): number => {
  const otherWeekDate = Luxon.DateTime.fromISO(otherWeek);
  const weekDiff = otherWeekDate.diff(actualWeek, 'weeks');
  const diffInDays = parseInt(weekDiff.toFormat('d'), 10);

  return daysToWeek(diffInDays);
};

/**
 * Converts a DurationObject into a human readable string
 * @param duration
 */
export const durationToString = (
  {hours, minutes}: Luxon.DurationObject,
  t: (key: string) => string
): string => {
  const times: string[] = [];
  if (hours) {
    times.push(`${hours} ${hours === 1 ? t(descriptions.hour) : t(descriptions.hours)}`);
  }

  if (minutes) {
    times.push(`${minutes} ${minutes === 1 ? t(descriptions.minute) : t(descriptions.minutes)}`);
  }

  return times.join(' ');
};

/**
 * Prints month of the Luxon date as a localized string
 * @param date
 */
export const monthToString = (date: Luxon.DateTime) => date.toLocaleString({month: 'long'});

/**
 * Prints year of the Luxon date
 * @param date
 */
export const yearToString = (date: Luxon.DateTime) => date.toLocaleString({year: 'numeric'});

/**
 * Prints month (as a localized string) and year of the Luxon date
 * @param date
 */
export const monthAndYearToString = (date: Luxon.DateTime) =>
  date.toLocaleString({year: 'numeric', month: 'long'});

/**
 * Converts startDate and endDate to a human readable string (DD.MM-DD.MM)
 * @param startDate
 * @param endDate
 */
export const dateRangeToString = (startDate: string, endDate: string) => {
  const start = Luxon.DateTime.fromISO(startDate);
  const end = Luxon.DateTime.fromISO(endDate);

  return `${start.toFormat('dd.MM.')}-${end.toFormat('dd.MM.')}`;
};
