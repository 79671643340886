/*
Copyright 2021, Staffbase GmbH and contributors.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {range} from 'lodash';
import log from 'loglevel';
import {DateTime} from 'luxon';
import {useCallback, useMemo, useState} from 'react';

import {dateToISOWeek, ISOtoDate} from '../helpers/date-utils';
import {Shift, WeeklyShiftsList} from '../types/shift';

export interface WeekDay {
  day: DateTime;
  today: boolean;
  highlighted: boolean;
}

const shiftIsOnDay = (shift: Shift, day: DateTime) => {
  const shiftStart = DateTime.fromISO(shift.start);
  return day.startOf('day') <= shiftStart && shiftStart <= day.endOf('day');
};

export const useWeekdays = (weekStart: DateTime, shifts: ReadonlyArray<Shift>) => {
  const today = useToday();

  return useMemo<readonly WeekDay[]>(() => {
    log.debug('computing calendar data ', today, shifts);

    return range(7).map((count: number) => {
      const day = weekStart.plus({days: count});
      return {
        day,
        today: day.hasSame(today, 'day'),
        highlighted: shifts && shifts.some((shift: Shift) => shiftIsOnDay(shift, day)),
      };
    });
  }, [weekStart, today, shifts]);
};

const firstShiftInWeek = (week: DateTime, shifts: WeeklyShiftsList) => {
  const shiftsInWeek = shifts[dateToISOWeek(week)];
  if (shiftsInWeek && shiftsInWeek.length > 0) {
    return shiftsInWeek[0];
  }
};

export const useWeekNavigation = (
  shifts: WeeklyShiftsList,
  onSelectShift?: (shift?: Shift) => void
) => {
  const today = useToday();
  const [selectedWeek, selectWeek] = useState(today.startOf('week'));
  const todaysShift = useTodaysShift(shifts[dateToISOWeek(today.startOf('week'))] || []);

  const goToPreviousWeek = useCallback(() => {
    const week = selectedWeek.minus({weeks: 1});
    selectWeek(week);
    if (onSelectShift) {
      onSelectShift(firstShiftInWeek(week, shifts));
    }
  }, [shifts, selectWeek, selectedWeek, onSelectShift]);

  const goToNextWeek = useCallback(() => {
    const week = selectedWeek.plus({weeks: 1});
    selectWeek(week);
    if (onSelectShift) {
      onSelectShift(firstShiftInWeek(week, shifts));
    }
  }, [shifts, selectWeek, selectedWeek, onSelectShift]);

  const goToToday = useCallback(() => {
    selectWeek(today.startOf('week'));

    if (onSelectShift) {
      onSelectShift(todaysShift || firstShiftInWeek(today.startOf('week'), shifts));
    }
  }, [selectWeek, today, todaysShift, onSelectShift, shifts]);

  return {
    selectedWeek,
    goToPreviousWeek,
    goToNextWeek,
    goToToday,
  };
};

export const useToday = () => {
  const [today, setToday] = useState<DateTime>(DateTime.local().startOf('day'));

  const now = DateTime.local();
  if (!now.hasSame(today, 'day')) {
    setToday(now.startOf('day'));
  }

  return today;
};

export const useTodaysShift = (shifts: ReadonlyArray<Shift>) => {
  const today = useToday();
  return useMemo(
    () => shifts.find((shift: Shift) => ISOtoDate(shift.start).hasSame(today, 'day')),
    [shifts, today]
  );
};
